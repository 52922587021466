export const STATE_NAMES = {
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CT: "Chhattisgarh",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TG: "Telangana",
  TR: "Tripura",
  UT: "Uttarakhand",
  UP: "Uttar Pradesh",
  WB: "West Bengal",
  AN: "Andaman and Nicobar Islands",
  CH: "Chandigarh",
  DN: "Dadra and Nagar Haveli and Daman and Diu",
  DL: "Delhi",
  JK: "Jammu and Kashmir",
  LA: "Ladakh",
  LD: "Lakshadweep",
  PY: "Puducherry",
  TT: "India",
};

export const colors = {
  red: "rgba(255, 16, 67, 1)",
  redTransparent: "rgba(255, 16, 67, 0.7)",
  blue: "rgba(4, 123, 255, 1)",
  blueTransparent: "rgba(4, 123, 255, 0.7)",
  green: "rgba(40, 167, 69, 1)",
  greenTransparent: "rgba(40, 167, 69, 0.7)",
  grey: "rgba(108, 117, 125, 1)",
  greyTransparent: "rgba(108, 117, 125, 0.7)",
};
