import React from "react";
import Table from "./Table";

function RightLanding() {
  return (
    <div>
      <Table />
    </div>
  );
}

export default RightLanding;
